<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-500 bg_image"
             :style="{'background-image': `url(${require(`@/assets/images/_new/cloud-slider.png`)})`}">
        </div>
        <!-- End Slider Area -->
			<div class="about-area about-style-4 rn-section-gap cloud-desc">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12">
							<div class="content">
								立足于腾讯云、阿里云、华为云平台提供专业服务，向合作伙伴提供云战略咨询，云安全咨询及建设，云数据迁移，云管平台原生应用、云运维等服务。协助各行各业客户进行数字化转型，并致力于帮助客户降本增效。
							</div>
							<div class="clouds">
								<div class="cloud">
									<img src="../assets/images/_new/cloud/tencent-cloud.svg">
								</div>
								<div class="cloud">
									<img src="../assets/images/_new/cloud/aliyun.svg">
								</div>
								<div class="cloud">
									<img src="../assets/images/_new/cloud/huaweicloud.svg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="about-area about-style-4 rn-section-gap bg-light">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12">
							<div class="content">
								<div class="inner text-center">
									<h3 class="title"><strong>热门产品及服务</strong></h3>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div v-for="(item, idx) in productData" class="col-lg-4 col-md-6 col-sm-12 col-12 padding--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
							<div class="card-box card-style-1 text-left">
								<div class="inner">
									<div class="image">
										<img :src="item.image" :alt="item.title">
									</div>
									<div class="content">
										<h4 class="title mb--20">
											{{ item.title }}
										</h4>
										<p class="description height-small b1 color-gray mb--0">
											{{ item.description }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row mb-5 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12">
							<div class="content">
								<div class="inner text-center">
									<h3 class="title"><strong>云服务</strong></h3>
								</div>
							</div>
						</div>
					</div>
					<div class="row" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-12">
							<div class="rn-default-tab">
								<ul class="nav nav-tabs tab-button" role="tablist">
									<li class="nav-item tabs__tab"
											role="presentation"
											v-for="(tabMenu, index) in tabData"
											:key="index">
										<button class="nav-link text-capitalize"
														:class="{'active': index === 0}"
														:id="`${tabMenu.id}-tab`"
														data-bs-toggle="tab"
														:data-bs-target="`#${tabMenu.id}`"
														type="button"
														role="tab"
														:aria-controls="tabMenu.id"
														:aria-selected="index === activeTab ? 'true' : 'false'"
														@click="activeTab = index">
											{{ tabMenu.title }}
										</button>
									</li>
								</ul>
								<div class="rn-tab-content tab-content">
									<div class="tab-pane fade"
											 :class="{'show active': index === 0}"
											 :id="content.id"
											 role="tabpanel"
											 :aria-labelledby="`${content.id}-tab`"
											 v-for="(content, index) in tabData"
											 :key="index">
										<div> {{ content.content }} </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
		import Counter from "@/components/elements/counterUp/Counter.vue";

    export default {
        name: 'Home',
        components: {Counter, Layout},
        data() {
            return {
							activeTab: 0,
							productData: [
								{
									title: '云服务器',
									description: '提供安全可靠的弹性计算服务，支持扩展及缩减',
									image: require(`@/assets/images/_new/cloud/p1.png`)
								},
								{
									title: '域名注册',
									description: '域名作为互联网门户入口，充当着现实生活中门',
									image: require(`@/assets/images/_new/cloud/p2.png`)
								},
								{
									title: '轻量应用服务器',
									description: '开箱即用、面向轻量应用场景的云服务器产品',
									image: require(`@/assets/images/_new/cloud/p3.png`)
								},
								{
									title: '实时音视频',
									description: '支持多人音视频通话和低延时互动直播两大场景化方案',
									image: require(`@/assets/images/_new/cloud/p4.png`)
								},
								{
									title: '内容分发网络',
									description: '通过将站点内容发布至遍布全球的海量加速节点',
									image: require(`@/assets/images/_new/cloud/p5.png`)
								},
								{
									title: 'TDSQL-C MySQL 版',
									description: '100%兼容 MySQL，为用户提供极致弹性、高性能服务',
									image: require(`@/assets/images/_new/cloud/p6.png`)
								},
							],
							tabData: [
								{
									id: 'no1',
									title: '云咨询',
									content: '我们为合作伙伴提供专业系统的咨询服务，提供迁移架构、优化部署、后台运维、技术支持等一系列咨询服务。'
								},
								{
									id: 'no2',
									title: '云迁移',
									content: '根据合作伙伴现在的系统情况和实际的开发阶段，我们可在腾讯云、Azure、AWS、阿里云、华为云、金山云、UCloud、运营商云等公有云平台之间，或者从IDC迁移到以上任何公有云平台上。我们的迁移服务包括基于镜像的自动化一键迁移、共享资源服务的公有云迁移、专有资源的私有云迁移、资源自动化的混合云迁移、开发环境作为服务的PaaS迁移，以及可移植的Docker迁移。'
								},
								{
									id: 'no3',
									title: '云实施',
									content: '在了解客户业务需求及现网环境基础上，由具备丰富交付经验的技术专家进行专业设计，保证业务系统的数据完整、准确和项目运作高效，确保实施阶段的顺利进行。从用户实际系统环境出发，针对用户系统和应用特点，为用户量身定制实施方案。'
								},
								{
									id: 'no4',
									title: 'MSP云运维服务',
									content: '我们出色的运维团队将为您提供24x7的监控和响应，及时处理异常情况，具备DevOps能力，更高效地实现开发运维自动化。运维团队利用云舶以及专业工单系统进行全天候监控和问题跟踪，技术支持团队保证快速响应，及时定位并排除故障。我们资深的专家团队、优秀的开发团队、专业的运维工具，以及雄厚的运维规模，都能保证为您扫除后顾之忧。'
								}
							]
            }
        }
    }
</script>
<style lang="scss">
.cloud-desc {
	.content {
		font-size: 20px;
	}
	.clouds {
		display: flex;
		gap: 100px;
		justify-content: center;
		margin-top: 60px;
		.cloud {
			width: 200px;
			img {
				height: 60px;
			}
		}
	}
}
</style>

<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-500 bg_image"
             :style="{'background-image': `url(${require(`@/assets/images/_new/gpu/slider.png`)})`}">
        </div>
        <!-- End Slider Area -->
			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row row--20 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-5">
							<img class="product-img" src="../assets/images/_new/gpu/s1.png" style="width: 100%" />
						</div>
						<div class="col-lg-7 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h4 class="title"><strong>GPU算力服务for人工智能</strong></h4>
									<div class="description">
										<div>人工智能领域的深度学习等应用，通常使用NVIDIA GPU进行模型训练和推理加速。因模型训练时间通常较长（几小时或几天），因此要求服务器运行稳定，配置多线程CPU和足够的内存。另深度学习的环境配置较耗时、复杂且易出错，是研发人员的一大痛点。我们针对以上几方面都做了专门优化。</div>
										<div class="items">
											<div>百兆光纤主干网，网络稳定性高达99.9%</div>
											<div>基于主流Ubuntu或windows操作系统，开机即可使用</div>
											<div>预装Cuda,cuDNN,python,tensorflow-gpu,pytorch,paddlepaddle等主流软件和框架</div>
											<div>支持SSH、VNC图形桌面、Jupyter Notebook等多种形式访问</div>
											<div>弹性灵活，开机计费，支持按分钟租用</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<separator />

			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row row--20 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-7 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h4 class="title"><strong>GPU渲染</strong></h4>
									<div class="description">
										<div>配备NVIDIA专业GPU的云主机，集合了大量图形设计专用的技术规格和特色，高稳定性，高渲染精度；同时配备了多线程CPU和超大内存，赋能大规模的模型处理能力，让您的3D建模更流畅，3D渲染速度更快，动画效果更完美。</div>
										<div class="items">
											<div>运营商主干网，网络稳定性高达99.9%</div>
											<div>支持多GPU渲染、GPU超大集群渲染、实时渲染</div>
											<div>专业图形显卡GPU，多线程CPU，大内存，超大硬盘</div>
											<div>主流windows操作系统，支持用户自安装软件，提供软件安装包</div>
											<div>支持远程桌面、VNC和向日葵访问</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-5">
							<img class="product-img" src="../assets/images/_new/gpu/s2.png" style="width: 100%" />
						</div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
		import Separator from "@/components/elements/separator/Separator.vue";

    export default {
        name: 'Home',
        components: {Separator, Layout},
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss">
.service {
	padding: 12px !important;
	.container {
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 40px;
		&.no-bg {
			background-color: rgb(248, 249, 250);
		}
		.title {
			font-size: 36px;
			color: white;
			line-height: 160px;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			color: #0f0f11;
			font-size: 22px;
			text-align: center;
			margin: 16px 12px 0 12px;
		}
	}
}
.items {
	div {
		margin-top: 10px;
	}
}
</style>

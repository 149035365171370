<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="copyright-right text-center">
                        <p class="copyright-text">
													All rights reserved © {{ new Date().getFullYear() }} 北京中炘云网科技有限公司 <a href="https://beian.miit.gov.cn/">京ICP备2024062508号-1</a>
												</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>

<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-500 bg_image"
             :style="{'background-image': `url(${require(`@/assets/images/_new/idc/slider.png`)})`}">
        </div>
        <!-- End Slider Area -->
			<div class="about-area about-style-4 rn-section-gap cloud-desc">
				<div class="container">
					<div class="row align-items-center mb--30" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12">
							<div class="content">
								<div class="inner text-center">
									<h3 class="title"><strong>中炘云网络T3+高端数据中心</strong></h3>
								</div>
							</div>
						</div>
					</div>
					<div class="row align-items-center" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-12">
							<div class="content">
								中炘云网是经营自有广播BGP、T3+数据中心的公司。我们的网络运行能力可为更多企业用户提供支撑，接入口总量高达1Tbps，用户可以体验高速稳定的网络。我们所经营的多个数据中心，提供超过5000平的空间，并且具有冗余性，可靠性，安全性和可扩展性等强大功能。我们提供具有高适用性的解决方案，以确保其能够为我们的客户带来更高价值，并且能够更优化的解决客户需求。 多年来我们一直致力于提供更优质的服务，为我们的客户提供360°网络监控及7x24小时全天候在线服务。
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="about-area about-style-4 rn-section-gap bg-light">
				<div class="container">
					<div class="row mb--30 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12">
							<div class="content">
								<div class="inner text-center">
									<h3 class="title"><strong>服务优势</strong></h3>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div v-for="(item, idx) in productData" class="col-lg-4 col-md-6 col-sm-12 col-12 padding--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
							<div class="card-box card-style-1 text-left" style="background: linear-gradient(180deg, rgba(106,218,241,0.2) 0%, rgba(142,242,249,0.01) 100%, rgba(2,0,36,1) 100%);
">
								<div class="inner">
									<div class="image">
										<img :src="item.image" :alt="item.title">
									</div>
									<div class="content">
										<h4 class="title mb--20">
											{{ item.title }}
										</h4>
										<p class="description b1 color-gray mb--0" style="height: 90px">
											{{ item.description }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="about-area about-style-4 advantage">
				<div class="container">
					<div class="row row--30 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-12">
							<div class="inner">
								<h3>中炘云网数据中心服务</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="advantage-tabs">
				<div class="container">
					<div class="row" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-12">
							<div class="row row--0 about-wrapper align-items-center theme-shape">
								<div class="col-lg-12">
									<div class="rn-default-tab">
										<ul class="nav nav-tabs tab-button" role="tablist">
											<li class="nav-item tabs__tab"
													role="presentation"
													v-for="(tabMenu, index) in tabData"
													:key="index">
												<button class="nav-link text-capitalize"
																:class="{'active': index === 0}"
																:id="`${tabMenu.id}-tab`"
																data-bs-toggle="tab"
																:data-bs-target="`#${tabMenu.id}`"
																type="button"
																role="tab"
																:aria-controls="tabMenu.id"
																:aria-selected="index === activeTab ? 'true' : 'false'"
																@click="activeTab = index">
													{{ tabMenu.title }}
												</button>
											</li>
										</ul>
										<div class="rn-tab-content tab-content" style="background: none">
											<div class="tab-pane fade"
													 :class="{'show active': index === 0}"
													 :id="content.id"
													 role="tabpanel"
													 :aria-labelledby="`${content.id}-tab`"
													 v-for="(content, index) in tabData"
													 :key="index">
												<div>
													<div class="banner">
														<img :src="content.image" />
													</div>
													<div class="service-cards">
														<div class="row">
															<div v-for="item in content.contents" class="col-lg-4 col-md-4 service-card">
																<div class="title">{{ item.title }}</div>
																<div class="description">{{ item.description }}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    export default {
        name: 'Home',
        components: {Layout},
        data() {
            return {
							activeTab: 0,
							productData: [
								{
									title: '跨数据中心传输',
									description: '承载全国和全球业务分布式部署，满足IDC之间的高速数据交换、灾备等场景需求。',
									image: require(`@/assets/images/_new/idc/s1.png`)
								},
								{
									title: '多线BGP',
									description: '覆盖全球16个Tier运营商，网络全面提速，优化用户访问体验。BGP动态对接电信、联通、移动等运营商。',
									image: require(`@/assets/images/_new/idc/s2.png`)
								},
								{
									title: '快速逐渐混合云',
									description: '供多样化IT架构，包括IDC、公有云及私有云，对所有基础设施进行内网互通，实现混合架构的协同工作，并实现企业逐步上云的IT规划。',
									image: require(`@/assets/images/_new/idc/s3.png`)
								}
							],
							tabData: [
								{
									id: 'no1',
									title: '网络服务',
									image: require(`@/assets/images/_new/idc/t1.png`),
									contents: [
										{
											title: '独享宽带',
											description: '提供电信、联通、移动、等多家运营商得全国IDC骨干节点宽带资源'
										},
										{
											title: '跨地域互联',
											description: '建立不同城市，两个数据中心的连接'
										},
										{
											title: '云连接',
											description: '建立共有云到数据中心之间的连接'
										}
									]
								},
								{
									id: 'no2',
									title: '托管服务',
									image: require(`@/assets/images/_new/idc/t2.png`),
									contents: [
										{
											title: '服务器托管',
											description: '提供灵活的机柜租用空间，享受高速网络 带宽、不间断电源、监控系统等高规格数据中心服务'
										},
										{
											title: '整机柜租用',
											description: '提供标准机架租用，电力可扩展性强'
										},
										{
											title: '围笼租用',
											description: '针对不同行业客户提供多样化围笼方案，提供定制化服务'
										}
									],
								},
								{
									id: 'no3',
									title: '安全服务',
									image: require(`@/assets/images/_new/idc/t3.png`),
									contents: [
										{
											title: '流量清洗',
											description: '具有多家运营商的优质宽带资源，流量清洗设备可防护大类流量攻击。防火墙设备集群冗余，稳定可靠'
										},
										{
											title: '漏洞扫描',
											description: '专业级漏洞扫描平台，一键检测网站、服务器等网络资源，有效防范风险漏洞'
										},
										{
											title: '其他安全服务',
											description: '提供备案、企业级CA信息安全整体解决方案、SSL证书、信息安全等级保护、应用防护WAF/WAD等安全服务'
										}
									],
								},
								{
									id: 'no4',
									title: '增值服务',
									image: require(`@/assets/images/_new/idc/t4.png`),
									contents: [
										{
											title: '同城/异地灾备实施',
											description: '可为客户提供专业的同城/异地灾备服务，帮助客户轻松实现双活云计算数据中心的搭建'
										},
										{
											title: '运维管理服务定制',
											description: '提供可定制化的运维管理服务，指在创导标准化、主动式的运维服务，打造系统化的运维服务管理体系'
										}
									],
								}
							]
            }
        }
    }
</script>
<style lang="scss">
.cloud-desc {
	.content {
		font-size: 20px;
	}
	.clouds {
		display: flex;
		gap: 100px;
		justify-content: center;
		margin-top: 60px;
		.cloud {
			width: 200px;
			img {
				height: 60px;
			}
		}
	}
}
.advantage {
	background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
	padding: 80px 0 120px 0 !important;
	.inner h3 {
		color: white !important;
	}
}
.advantage-tabs {
	.about-wrapper {
		background: #f5f8fa;
		margin-top: -82px;
		border-radius: 5px 5px 0 0;
	}
	.rn-default-tab{
		.rn-tab-content {
			margin-top: 2px;
			padding: 0;
			.banner {
				width: 100%;
				img {
					width: 100%;
				}
			}
			.service-cards {
				padding: 20px;
				height: 240px;
				.service-card {
					padding: 20px;
					.title {
						font-size: 18px;
						font-weight: bolder;
					}
					.description {
						margin-top: 10px;
					}
				}
			}
		}
		.tab-button .tabs__tab .nav-link {
			padding: 26px 32px 24px;
			font-size: 20px;
			&.active {
				border-bottom: 3px solid #006cb7;
			}
		}
	}
}
</style>

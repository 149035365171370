<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-500 bg_image"
             :style="{'background-image': `url(${require(`@/assets/images/_new/about/slider.png`)})`}">
        </div>
        <!-- End Slider Area -->
			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12 mb--40 text-center">
							<h3 class="title"><strong>公司介绍</strong></h3>
						</div>
						<div class="col-lg-6 mt_md--40 mt_sm--40">
							<div class="description" style="font-size: 18px;line-height: 38px;text-align: justify">
								<div>北京中炘云网科技有限公司（简称：中炘云网）总部位于北京，中炘云网致力于为客户提供便捷、稳定、安全、性价比高的互联网整体资源解决方案，是为用户提供一站式解决方案的增值电信服务提供商，是腾讯云钻石代理商、腾讯云授权服务中心。</div>
								<div>中炘云网目前与国内外电信运营商合作经营10个以上互联网数据中心，经营机房面积合计超过5000平米，运维的服务器数量超过10000台，带宽总拥有量超过1Tbps。 作为互联网基础设施提供商，公司在全国各地拥有多个T3+等级的IDC机房，同时我们专业的技术运营团队将为客户提供“全程负责”、“全冗余”、“7*24小时”的机房服务。并且“一站式”投诉解决方法及“全程客户满意度监控”能够确保客户在任何情况下均能享受到高品质的技术服务。</div>
							</div>
						</div>
						<div class="col-lg-6">
							<img class="product-img" src="../assets/images/_new/about/s1.png" />
						</div>
					</div>
				</div>
			</div>

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap bg-light">
            <div class="container">
                <div class="row mb--40" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12 text-center">
                        <h3>公司理念</h3>
                    </div>
                </div>
                <div class="row row--10" data-aos="fade-up" data-aos-delay="200">
									<div class="col-lg-3 col-md-6 idea">
										<div class="container">
											<img class="img" src="../assets/images/_new/about/p0.png">
											<div class="title">企业愿景</div>
											<div class="content">多元化的数据中心基础服务、云服务提供</div>
										</div>
									</div>
									<div class="col-lg-3 col-md-6 idea">
										<div class="container">
											<img class="img" src="../assets/images/_new/about/p1.png">
											<div class="title">价值观</div>
											<div class="content">正直、 进取、 合作、 创新</div>
										</div>
									</div>
									<div class="col-lg-3 col-md-6 idea">
										<div class="container">
											<img class="img" src="../assets/images/_new/about/p2.png">
											<div class="title">企业使命</div>
											<div class="content">通过专业的基础服务、云服务能力提升用户体验和企业影响力</div>
										</div>
									</div>
									<div class="col-lg-3 col-md-6 idea">
										<div class="container">
											<img class="img" src="../assets/images/_new/about/p3.png">
											<div class="title">客户服务</div>
											<div class="content">效率、 创新、 责任、 团队、 诚信</div>
										</div>
									</div>
								</div>
            </div>
        </div>
        <!-- End Elements Area -->
			<div class="rwt-tab-area rn-section-gap">
				<div class="container">
					<div class="row mb--50" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12 text-center">
							<h3>联系我们</h3>
						</div>
					</div>
					<div class="row row--20" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-12">
							<baidu-map
								class="bm-view"
								:center="center"
								:zoom="15"
								@ready="handler"
							>
								<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
								<bm-marker :position="center" :dragging="true" @click="show = true">
									<bm-info-window :show="show" @close="show = false" @open="show = true">
										<h4>北京中炘云网科技有限公司</h4>
										<div>地址：北京市海淀区农大南路硅谷亮城2B座7层</div>
										<div>邮编：100085</div>
									</bm-info-window>
								</bm-marker>
							</baidu-map>
						</div>
					</div>
					<div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    export default {
        name: 'Home',
        components: {Layout},
				methods: {
					handler ({BMap, map}) {
						this.center.lng = 116.319404
						this.center.lat = 40.033063
					}
				},
        data() {
            return {
							center: {lng:0, lat: 0},
							show: true
            }
        }
    }
</script>
<style lang="scss">
.idea {
	padding: 10px !important;
	.container {
		background-color: white;
		height: 260px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 32px;
		.title {
			font-size: 22px;
			margin-top: 16px;
			color: #0f0f11;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			font-size: 18px;
			margin: 16px 12px 0 12px;
		}
	}
}
.bm-view {
	width: 100%;
	height: 480px;
}
</style>

<template>
	<Layout>
		<div class="slider-area slider-style-1 variation-default height-500 bg_image"
				 :style="{'background-image': `url(${require(`@/assets/images/_new/2.jpg`)})`}">
		</div>
		<div class="about-area about-style-4 rn-section-gap cloud-desc bg-light">
			<div class="container">
				<div class="row align-items-center mb--30" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-12">
						<div class="content">
							<div class="inner text-center">
								<h3 class="title"><strong>CherDrop - 极炘无线投屏</strong></h3>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-center" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-12">
						<div class="content">
							CherDrop - 极炘无线投屏是中炘云网自研系统，历经多版本的内部场景磨练，为会议室投屏提供卓越体验的企业级产品。
						</div>
					</div>
				</div>
				<div class="row mt--30">
					<div v-for="(item, idx) in productData" class="col-lg-3 col-md-6 col-sm-12 col-12 padding--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
						<div class="card-box card-style-1 text-left" style="background: linear-gradient(180deg, rgba(106,218,241,0.2) 0%, rgba(142,242,249,0.01) 100%, rgba(2,0,36,1) 100%);
">
							<div class="inner">
								<div class="full-image">
									<img :src="item.image" :alt="item.title">
								</div>
								<div class="content mt--20 mb--20">
									<h4 class="title">
										{{ item.title }}
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Slider Area -->
		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d1.png" style="width: 100%" />
					</div>
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>全端支持</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>
										在众多智能电视品牌与机型琳琅满目的市场中，各厂家设备硬件配置千变万化。CherDrop - 极炘无线投屏技术始终致力于与所有主流智能电视进行深度的软硬件兼容适配，旨在为用户带来前所未有的极致投屏享受。已经成功助力数万台智能电视解锁投屏功能，让大屏互动触手可及。
									</div>
									<div class="mt--10">
										无论是Windows或macOS系统电脑，还是Android或iOS系统手机，都可以通过CherDrop无线投屏实现无缝投屏，轻松享受跨屏互联的便捷与乐趣。
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>一键投屏</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>电脑访问CherDrop - 极炘无线投屏，无需数据线和转接口，即可将电脑内容一键上大屏。</div>
									<div class="items">
										<ul class="feature-list">
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让企业会议更方便</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让游戏体验更震撼</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让观影场景更享受</h4>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d2.png" style="width: 100%" />
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d3.png" style="width: 100%" />
					</div>
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>如何将手机投屏到电脑端？</strong></h4>
								<div class="description" style="font-size: 18px">
									<div class="mt--10">
										<ul class="feature-list">
											<li class="adv">
												<div class="icon" style="font-size: 14px">1</div>
												<div class="title-wrapper">
													<h4 class="title">使手机和电脑设备处于相同网络环境下</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">2</div>
												<div class="title-wrapper">
													<h4 class="title">手机上打开CherDrop - 极炘无线投屏</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">3</div>
												<div class="title-wrapper">
													<h4 class="title">扫描二维码，完成投屏</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">4</div>
												<div class="title-wrapper">
													<h4 class="title">在电脑浏览器上直接访问CherDrop - 极炘无线投屏链接</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">5</div>
												<div class="title-wrapper">
													<h4 class="title">或输入投屏码，完成投屏</h4>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '../components/common/Layout.vue'
import Separator from "@/components/elements/separator/Separator.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
	name: 'CheerDrop',
	components: {Icon, Separator, Layout},
	data() {
		return {
			productData: [
				{
					title: '兼容企业级网络架构',
					image: require(`@/assets/images/cherdrop/a1.png`)
				},
				{
					title: '功能强大的企业级管理后台',
					image: require(`@/assets/images/cherdrop/a2.png`)
				},
				{
					title: '企业办公软件集成',
					image: require(`@/assets/images/cherdrop/a3.png`)
				},
				{
					title: '信息发布，会议室信息展示',
					image: require(`@/assets/images/cherdrop/a4.png`)
				}
			],
		}
	}
}
</script>
<style lang="scss">
.service {
	padding: 12px !important;
	.container {
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 40px;
		&.no-bg {
			background-color: rgb(248, 249, 250);
		}
		.title {
			font-size: 36px;
			color: white;
			line-height: 160px;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			color: #0f0f11;
			font-size: 22px;
			text-align: center;
			margin: 16px 12px 0 12px;
		}
	}
}
.items {
	div {
		margin-top: 10px;
	}
}
</style>
